import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import WhoWeArePage from './components/WhoWeArePage';
import ServicesPage from './components/ServicesPage';
import PreProductionPage from './components/PreProductionPage';
import ProductionPage from './components/ProductionPage';
import PostProductionPage from './components/PostProductionPage';
import ContactUsPage from './components/ContactUsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/who-we-are" element={<WhoWeArePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/pre-production" element={<PreProductionPage />} />
        <Route path="/production" element={<ProductionPage />} />
        <Route path="/post-production" element={<PostProductionPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
