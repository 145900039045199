import React from 'react';
import { Helmet } from 'react-helmet'; 
import Navbar from './Navbar';
import Footer from './Footer';

const HomePage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Home - Vertex Global Holding Inc.</title>
      </Helmet>
      <Navbar />
      <div className="pageBg h-full  flex-grow flex items-center justify-center">
        <div className="relative w-full h-[60vh] md:h-[80vh] lg:h-[100vh] overflow-hidden">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source src="/Assets/Header Video New.mp4" type="video/mp4" loading="lazy" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
