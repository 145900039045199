import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Footer from './Footer';

const PostProductionPage = () => {
  return (
    <div className="pageBg pt-4 min-h-screen relative text-white flex flex-col">
      <Navbar />

      <motion.div
        className="absolute top-[-19.75rem] left-[-176px] lg:w-[600px] lg:h-[600px] w-[300px] h-[300px] z-10"
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 0.5, scale: 1 }}
        transition={{ duration: 3.5, ease: "easeInOut" }}
        style={{ clipPath: 'circle(80% at 100% 0)', rotate: '-220deg' }}
        whileTap={{ scale: 1 }}
      >
        <motion.img
          src="/Assets/Big Circle.png"
          alt="Big Circle"
          className="object-cover w-full h-full"
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 60, ease: "linear" }}
        />
      </motion.div>

      {/* Video Editing Section */}
      <motion.div
        className="flex flex-col lg:flex-row items-center justify-center flex-grow max-w-7xl py-12 mx-auto px-4"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <div className="relative flex justify-center items-center w-full lg:w-1/2 lg:h-[600px] xl:h-[800px] h-[300px] md:h-[400px]">
          <img
            src="/Assets/Big Circle.png"
            alt="Big Circle"
            className="absolute w-full h-full object-contain"
          />
          <motion.img
            src="/Assets/Video Editing.png"
            alt="Video Editing"
            className="absolute w-[180px] h-[180px] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] xl:w-[510px] xl:h-[510px] rounded-full object-cover"
            initial={{ x: -200, scale:0.8 }}
            animate={{ x: 0 }}
            transition={{ duration: 1.5, ease: 'easeInOut' }}
            whileHover={{ scale: 0.85 }}
          />
        </div>
        <motion.div
          className="w-full lg:w-1/2 mt-8 lg:mt-0 pl-0 lg:pl-10"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
        >
          <h2 className="text-xl lg:text-2xl font-bold mb-4">Video Editing :</h2>
          <p className="text-xs lg:text-sm leading-relaxed text-justify mb-4">
            <strong>Professional Video Editing</strong><br />
            Expertly piece together raw footage to create a cohesive and engaging narrative. Our editors ensure smooth transitions, proper pacing, and a compelling storyline, working closely with directors to achieve the desired vision.
          </p>
          <p className="text-xs lg:text-sm leading-relaxed text-justify mb-4">
            <strong>Color Grading</strong><br />
            Enhance the film's visual tone and consistency by adjusting colors, contrast, and brightness. This process ensures a unified aesthetic, highlighting the film's mood and style while correcting any color discrepancies.
          </p>
          <p className="text-xs lg:text-sm leading-relaxed text-justify">
            <strong>Visual Effects (VFX)</strong><br />
            Create and integrate cutting-edge visual effects to add depth, realism, and creativity to your project. From subtle enhancements like background additions to complex CGI, our VFX team brings your imaginative elements to life.
          </p>
        </motion.div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default PostProductionPage;
