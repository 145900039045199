import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="fixed top-0  left-0 w-full pageBg text-white p-4 z-50 overflow-y-auto">
      <div className="max-w-7xl mx-auto lg:flex justify-between items-center">
        <div className="text-md  lg:text-2xl font-semibold">
          <Link to="/"><h1>VERTEX GLOBAL HOLDING INC.</h1></Link>
        </div>
        <div className="lg:hidden flex flex-col items-end">
          <button onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
          </button>
        </div>
        <div className={`lg:flex lg:space-x-4 lg:items-center ${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
          <Link 
            to="/" 
            className={isActive('/') ? 'text-purple-500 underline font-bold block lg:inline-block mt-4 lg:mt-0' : 'hover:text-purple-300 block lg:inline-block mt-4 lg:mt-0'}
            onClick={toggleMenu}
          >
            Home
          </Link>
          <Link 
            to="/who-we-are" 
            className={isActive('/who-we-are') ? 'text-purple-500 underline font-bold block lg:inline-block mt-4 lg:mt-0' : 'hover:text-purple-300 block lg:inline-block mt-4 lg:mt-0'}
            onClick={toggleMenu}
          >
            Who We Are
          </Link>
          <Link 
            to="/services" 
            className={isActive('/services') ? 'text-purple-500 underline font-bold block lg:inline-block mt-4 lg:mt-0' : 'hover:text-purple-300 block lg:inline-block mt-4 lg:mt-0'}
            onClick={toggleMenu}
          >
            Services
          </Link>
          <Link 
            to="/contact-us"
            className={isActive('/contact-us') ? 'text-purple-500 underline font-bold block lg:inline-block mt-4 lg:mt-0' : 'hover:text-purple-300 block lg:inline-block mt-4 lg:mt-0'}
            onClick={toggleMenu}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
