import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform  } from 'framer-motion';
import Navbar from './Navbar';
import Footer from './Footer';

const ServicesPage = () => {
  const { scrollY } = useScroll();
  const parallaxY = useTransform(scrollY, [0, 300], [0, 50]);
  return (
    <div className="pageBg text-white min-h-screen flex flex-col relative justify-between">
      <Helmet>
        <title>Services - Vertex Global Holding Inc.</title>
      </Helmet>
      <Navbar />

            {/* Animated Half Bigger Ring */}
            <motion.div
        className="absolute top-[-24rem] 2xl:top-[-14rem] left-[-15rem] lg:w-[600px] lg:h-[600px] w-[300px] h-[300px] z-10 overflow-y-hidden "
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 0.5, scale: 1 }}
        transition={{ duration: 3.5, ease: "easeInOut" }}
        style={{
          clipPath: "circle(80% at 100% 0)",
          rotate: "120deg",
          y: parallaxY,
        }}
        // whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 1 }}
      >
        <motion.img
          src="/Assets/Big Circle.png"
          alt="Big Circle"
          className="object-cover w-full h-full"
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 60, ease: "linear" }}
        />
      </motion.div>

      <motion.div
        className="flex flex-col items-center justify-center pt-8 flex-grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        {/* Services Section */}
        <div className="flex flex-col md:flex-row justify-around w-full max-w-7xl mt-10 space-y-6 md:space-y-0 md:space-x-6 px-4">
          {/* Pre-Production */}
          <motion.div
            className="text-center"
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <motion.div
              className="relative mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.3 }}
            >
              <img
                src="/Assets/Pre-Production Image.png"
                alt="Pre-Production"
                className="w-40 h-40 md:w-52 md:h-52 rounded-full object-cover shadow-lg mx-auto"
              />
            </motion.div>
            <motion.h3
              className="text-lg md:text-xl font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7, delay: 0.5 }}
            >
              Pre-Production
            </motion.h3>
            <div className="relative mt-2">
              <img src="/Assets/Tab.png" alt="Read More Tab" className="w-24 md:w-32 mx-auto" />
              <Link
                to="/pre-production"
                className="absolute inset-0 text-white  focus:outline-none flex items-center justify-center"
              >
                Read More
              </Link>
            </div>
          </motion.div>

          {/* Production */}
          <motion.div
            className="text-center"
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <motion.div
              className="relative mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.6 }}
            >
              <img
                src="/Assets/Production Image.png"
                alt="Production"
                className="w-40 h-40 md:w-52 md:h-52 rounded-full object-cover shadow-lg mx-auto"
              />
            </motion.div>
            <motion.h3
              className="text-lg md:text-xl font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7, delay: 0.8 }}
            >
              Production
            </motion.h3>
            <div className="relative mt-2">
              <img src="/Assets/Tab.png" alt="Read More Tab" className="w-24 md:w-32 mx-auto" />
              <Link
                to="/production"
                className="absolute inset-0 text-white  focus:outline-none flex items-center justify-center"
              >
                Read More
              </Link>
            </div>
          </motion.div>

          {/* Post-Production */}
          <motion.div
            className="text-center"
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <motion.div
              className="relative mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.9 }}
            >
              <img
                src="/Assets/Post-Production Image.png"
                alt="Post-Production"
                className="w-40 h-40 md:w-52 md:h-52 rounded-full object-cover shadow-lg mx-auto"
              />
            </motion.div>
            <motion.h3
              className="text-lg md:text-xl font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7, delay: 1.1 }}
            >
              Post-Production
            </motion.h3>
            <div className="relative mt-2">
              <img src="/Assets/Tab.png" alt="Read More Tab" className="w-24 md:w-32 mx-auto" />
              <Link
                to="/post-production"
                className="absolute inset-0 text-white  focus:outline-none flex items-center justify-center"
              >
                Read More
              </Link>
            </div>
          </motion.div>
        </div>
      </motion.div>

      <Footer />
    </div>
  );
}

export default ServicesPage;
