import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Footer from './Footer';

const ProductionPage = () => {
  return (
    <div className="pageBg pt-4 min-h-full relative bg-red-500 text-white flex flex-col">
      <Navbar />

      <motion.div
        className="absolute top-[-19.75rem] hidden right-[-116px] lg:w-[600px] lg:h-[600px] w-[300px] h-[300px] "
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 0.5, scale: 1 }}
        transition={{ duration: 3.5, ease: "easeInOut" }}
        style={{ clipPath: 'circle(80% at 100% 0)', rotate: '-200deg' }}
        whileHover={{ boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)"}}
        whileTap={{ scale: 1 }}
      >
        <motion.img
          src="/Assets/Big Circle.png"
          alt="Big Circle"
          className="object-cover w-full h-full"
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 60, ease: "linear" }}
        />
      </motion.div>

      {/* Hair & Make-Up Section */}
      <motion.div
        className="flex flex-col lg:flex-row items-center justify-center flex-grow max-w-7xl py-12 mx-auto px-4"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <motion.div
          className="relative flex justify-center items-center w-full lg:w-1/2 lg:h-[600px] xl:h-[800px] h-[300px] md:h-[400px]"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <img
            src="/Assets/Big Circle.png"
            alt="Big Circle"
            className="absolute w-full h-full object-contain"
          />
          <motion.img
            src="/Assets/Hair & Makeup.png"
            alt="Hair & Makeup"
            className="absolute w-[250px] h-[250px] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] xl:w-[510px] xl:h-[510px] rounded-full object-cover"
            initial={{ scale: 0.8, x: -200 }}
            animate={{ scale: 0.8, x: 0 }}
            whileHover={{ scale: 0.85 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          />
        </motion.div>
        <motion.div
          className="w-full lg:w-1/2 mt-8 lg:mt-0 pl-0 lg:pl-10"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }}
        >
          <h2 className="text-xl lg:text-2xl font-bold mb-4">Make-Up & Hair :</h2>
          <motion.p
            className="text-xs lg:text-sm leading-relaxed mb-4 text-justify"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <strong>Skilled Artists</strong><br />
            Provide talented make-up and hair artists to apply and maintain looks throughout the production. These professionals ensure that each character's appearance aligns with the director's vision, offering expertise in various styles and techniques to suit different genres and settings. They are adept at handling everything from everyday looks to intricate period-specific styles, ensuring consistency throughout the shoot.
          </motion.p>
          <motion.p
            className="text-xs lg:text-sm leading-relaxed"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.2 }}
          >
            <strong>Special Effects</strong><br />
            Supply materials and expertise for special effects make-up, including prosthetics and body paint. Our team excels in creating realistic wounds, scars, and other effects necessary for a particular character. We use top-grade materials to ensure durability and comfort for actors.
          </motion.p>
        </motion.div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default ProductionPage;
