import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { motion, useScroll, useTransform } from "framer-motion";

const WhoWeArePage = () => {
  // Inside your component
  const { scrollY } = useScroll();
  const parallaxY = useTransform(scrollY, [0, 300], [0, 50]);

  return (
    <div className="pageBg min-h-screen text-white relative overflow-hidden">
      <Helmet>
        <title>Who We Are - Vertex Global Holding Inc.</title>
      </Helmet>
      <Navbar />

      {/* Animated Half Bigger Ring */}
      <motion.div
        className="absolute top-[-19.75rem] right-[-116px] lg:w-[600px] lg:h-[600px] w-[300px] h-[300px] z-10"
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 0.5, scale: 1 }}
        transition={{ duration: 3.5, ease: "easeInOut" }}
        style={{
          clipPath: "circle(80% at 100% 0)",
          rotate: "-200deg",
          y: parallaxY,
        }}
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 1 }}
      >
        <motion.img
          src="/Assets/Big Circle.png"
          alt="Big Circle"
          className="object-cover w-full h-full"
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 60, ease: "linear" }}
        />
      </motion.div>

      <motion.div
        className="flex flex-col lg:flex-row h-full relative z-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Left Section with Image */}
        <motion.div
          className="relative lg:w-1/2 w-full mt-10  h-64 lg:h-full"
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 3, ease: "easeInOut",type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.05 }}
        >
          <img
            src="/Assets/Who We Are.png"
            alt="Who We Are"
            className="object-cover h-full w-full"
            style={{
              clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
            }}
          />
        </motion.div>

        {/* Right Section with Text Content */}
        <motion.div
          className="lg:w-1/2 w-full flex flex-col items-start justify-end mb-20 mr-20 p-6 lg:p-10 text-justify"
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-2xl lg:text-4xl font-bold mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
            whileHover={{ color: "#FFC107" }}
          >
            Who We Are:
          </motion.h2>
          <motion.p
            className="text-xs lg:text-sm leading-relaxed mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 1 }}
            whileHover={{ scale: 1.02 }}
          >
            Vertex Global is a leading film production company that focuses on
            providing top-tier production services to its clients. Our passion
            for filmmaking inspired the creation of Vertex Global.
          </motion.p>
          <motion.p
            className="text-xs lg:text-sm leading-relaxed mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4, duration: 1 }}
            whileHover={{ scale: 1.02 }}
          >
            What sets us apart from other top film production companies is our
            commitment to honesty and full transparency. Our work reflects the
            integrity and trust we uphold in all our dealings. The value and
            loyalty we have built over time have established us as trusted
            partners.
          </motion.p>
          <motion.p
            className="text-xs lg:text-sm leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.6, duration: 1 }}
            whileHover={{ scale: 1.02 }}
          >
            Exceeding client expectations consistently allows us to achieve and
            surpass our own records. Our primary goal is to earn and maintain
            the trust of our clients through relentless dedication. As a
            full-service production company, we value the competence and
            creativity of our partners and strive for swift execution to serve
            our clients in the best possible manner.
          </motion.p>
        </motion.div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default WhoWeArePage;
