import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
// import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="pageBg  text-purple-200 py-6 ">
      <div className="max-w-7xl mx-auto px-4">
        <div className="ftext text-center mt-1">
          <p className="text-xs md:text-sm">&copy; 2024 Vertex Global Holding Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
